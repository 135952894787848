import { Mesh, Vector3 } from 'three-full';
import MovingAverage from './utils/MovingAverage';

export default class Smasher extends Mesh {
  constructor(container, geo, mat) {
    super(geo, mat);

    this.container = container;
    this.mouse = {x: this.container.clientWidth, y: this.container.clientHeight};

    this.velocityStats = document.querySelector(".stats-velocity");

    this.mathVec = new Vector3();
    this.MOVING_AVERAGE_SIZE = 10;
    this.prevPosition = new Vector3();
    this.velocity = new MovingAverage(this.MOVING_AVERAGE_SIZE);

    this.OnMouseMove = this.OnMouseMove.bind(this);
    this.OnTouchMove = this.OnTouchMove.bind(this);
    this.container.addEventListener('mousemove', this.OnMouseMove);
    document.addEventListener('touchstart', this.OnTouchMove, false);
    document.addEventListener('touchmove', this.OnTouchMove, false);
  }

  OnMouseMove(e) {
    this.mouse.x = (e.clientX / this.container.clientWidth) * 2 - 1;
    this.mouse.y = - (e.clientY / this.container.clientHeight) * 2 + 1;
  }

  OnTouchMove(event) {
    this.mouse.x = (event.changedTouches[0].clientX / this.container.clientWidth ) * 2 - 1;
    this.mouse.y = - ( event.changedTouches[0].clientY / this.container.clientHeight ) * 2 + 1;
  }

  calculateVelocity() {
    this.mathVec.subVectors(this.prevPosition, this.position);//.divideScalar(delta);
    if (!isFinite(this.mathVec.x) || !isFinite(this.mathVec.y)) {
      // not sure why this becomes infinite sometimez.
      // this.prevPosition.copy(this.position);
      return;
	}
    this.velocity.add(this.mathVec);
    this.prevPosition.copy(this.position);

    let currentAvg = this.velocity.getAvg().length();
    if (isNaN(currentAvg)) return;
    this.velocityStats.innerHTML = `Velocity: ${currentAvg.toFixed(0)}`;
  }

  update(camera, delta) {

    let vector = new Vector3(this.mouse.x, this.mouse.y, 0);
    vector.unproject( camera );
    let dir = vector.sub( camera.position ).normalize();
    let distance = - camera.position.z / dir.z;
    let pos = camera.position.clone().add( dir.multiplyScalar( distance ) );
    this.rotation.set( this.mouse.y * 1.5, this.mouse.x * -1.5, this.rotation.z);
    this.position.set( pos.x, pos.y, 15 );

    this.calculateVelocity();
  }
}
