import Main from './app/main.js';
import LevelSelect from './app/LevelSelect.js';
import PromisedLoad from './app/utils/PromisedLoad.js';

require('normalize.css/normalize.css');
require('./styles/index.scss');

document.addEventListener("DOMContentLoaded", () => {

  const btn = document.querySelector('#instructions-btn');
  const instructions = document.querySelector('.instructions-container');
  const instructionsBox = document.querySelector('.instructions');
  const container = document.getElementById('threejs-container');
  const loader = document.querySelector('.loader');
  const loaderText = document.querySelector("#loader-text");
  const loaderBtn = document.querySelector('#loader-btn');
  const gui = document.querySelector('.ui');
  const levelSelectContainer = document.querySelector("#levelselect");
  const levelSelectText = document.querySelector("#levelselect-text");

  const main = new Main({
    container: container
  });

  const levelSelect = new LevelSelect(levelSelectContainer, levelSelectText);
  const leftLevel = document.querySelector("#levelselect-left");
  const rightLevel = document.querySelector("#levelselect-right");

  btn.addEventListener('click', (e) => {
    loader.setAttribute('style', 'display: block;');
    instructions.parentElement.removeChild(instructions);

    const level = levelSelect.getCurrentLevel();
    main.Initialize(level.model, level.modelName, level.song).then(() => {
      loaderBtn.setAttribute('style', 'display: block;');
      loaderText.innerHTML = 'Done loading.';
      loaderBtn.addEventListener('click', (e) => {
        main.bgAudio.play();
        loader.parentElement.removeChild(loader);
        gui.style = 'display: block;';

        main.Animate();
      });
    });
  });

  leftLevel.addEventListener('click', (e) => {
    levelSelect.prevLevel();
  });
  rightLevel.addEventListener('click', (e) => {
    levelSelect.nextLevel();
  });
  instructionsBox.setAttribute('style', 'display: block;');


});


