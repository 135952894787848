export default class LevelSelect {
  constructor(container, textDiv) {
    this.container = container;
    this.textDiv = textDiv;
    this.currentLevel = 0;
    this.levels = [
      {
        name: "Adam",
        model: "/models/Adam_Fractured.gltf",
        modelName: "Adam_Fractured",
        song: "/songs/Witching_Hour.mp3",
        songTitle: "Witching Hour",
        profile: "/profiles/adam.png"
      },
      {
        name: "Caleb",
        model: "/models/Caleb_Fractured.gltf",
        modelName: "Caleb_Fractured",
        song: "/songs/flying_octopus.mp3",
        songTitle: "Flying Octopus",
        profile: "/profiles/caleb.png"
      },
      {
        name: "CGI",
        model: "/models/ChrisG_Fractured.gltf",
        modelName: "ChrisG_Fractured",
        song: "/songs/spider_on_the_moon.mp3",
        songTitle: "Spider on the Moon"
      },
      {
        name: "Christopher",
        model: "/models/ChrisJ_Fractured.gltf",
        modelName: "ChrisJ_Fractured",
        song: "/songs/teleportal.mp3",
        songTitle: "Teleportal"
      },
      {
        name: "Daniel",
        model: "/models/Daniel_Fractured.gltf",
        modelName: "Daniel_Fractured",
        song: "/songs/Witching_Hour.mp3",
        songTitle: "Witching Hour"
      },
      {
        name: "Joey",
        model: "/models/Joey_Fractured.gltf",
        modelName: "Joey_Fractured",
        song: "/songs/flying_octopus.mp3",
        songTitle: "Flying Octopus"
      },
      {
        name: "Mike",
        model: "/models/Mike_Fractured.gltf",
        modelName: "Mike_Fractured",
        song: "/songs/spider_on_the_moon.mp3",
        songTitle: "Spider on the Moon"
      },
      {
        name: "Nate",
        model: "/models/Nate_Fractured.gltf",
        modelName: "Nate_Fractured",
        song: "/songs/teleportal.mp3",
        songTitle: "Teleportal"
      },
      {
        name: "Paul",
        model: "/models/Paul_Fractured.gltf",
        modelName: "Paul_Fractured",
        song: "/songs/Witching_Hour.mp3",
        songTitle: "Witching Hour"
      },
      {
        name: "Silas",
        model: "/models/Silas_Fractured.gltf",
        modelName: "Silas_Fractured",
        song: "/songs/flying_octopus.mp3",
        songTitle: "Flying Octopus"
      },
      {
        name: "Tyler",
        model: "/models/Tyler_Fractured.gltf",
        modelName: "Tyler_Fractured",
        song: "/songs/spider_on_the_moon.mp3",
        songTitle: "Spider on the Moon"
      }
    ];
    this.updateText(0);
  }

  getCurrentLevel() {
    return this.levels[this.currentLevel];
  }

  nextLevel() {
    this.currentLevel = (this.currentLevel + 1) % this.levels.length;
    this.updateText(this.currentLevel);
  }

  prevLevel() {
    this.currentLevel = (this.levels.length + (this.currentLevel - 1)) % this.levels.length;
    this.updateText(this.currentLevel);
  }

  updateText(index) {
    const level = this.levels[index % this.levels.length];
    if (level.profile != null) {
      this.textDiv.innerHTML = `
        <img src="${level.profile}" height="120" />
        <h4>${level.name}</h4>
        <div>Song: ${level.songTitle}</div>
      `;
    } else {
      this.textDiv.innerHTML = `
        <div style="height: 120px; width: 200px; line-height: 120px;">N/A</div>
        <h4>${level.name}</h4>
        <div>Song: ${level.songTitle}</div>
      `;
    }

  }
}
