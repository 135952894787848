import { Vector3 } from 'three-full';

export default class MovingAverage {
	constructor(length) {
		this.maxTotal = length;
		this.emptyVec = new Vector3();
		this.values = new Array(length).fill(this.emptyVec.clone());
		this.avg = new Vector3(0, 0);
		this.avgWorkingVec = new Vector3();
		this.head = 0;
    this.total = 0;
	}

	add(newVec) {
		this.head = (this.head + 1) % this.maxTotal;
		let emptyPos = (this.maxTotal + this.head - 1) % this.maxTotal;
		this.values[emptyPos].copy(newVec);
		this.total = (this.total + 1 ) % this.maxTotal;
	}

	clear() {
		this.values.fill(this.emptyVec);
		this.avg.set(0, 0, 0);
		this.head = 0;
		this.total = 0;
	}

	getAvg() {
		this.avg.set(0, 0, 0);
		for (let idx = 0; idx < this.values.length; idx++) {
			this.avg.add(this.values[idx]);
		}
		this.avg.divideScalar(this.total);
		return this.avg;
	}
}
