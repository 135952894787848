import { Color, DirectionalLight, WebGLRenderer, Scene, PerspectiveCamera, Vector3, Clock, BoxGeometry, Raycaster, AudioListener, Audio, AudioLoader, MeshPhongMaterial, AudioAnalyser, EffectComposer, RenderPass, GlitchPass, UnrealBloomPass, Vector2, WebVR } from 'three-full';
import PromisedLoad from './utils/PromisedLoad.js';
import Smashable from './Smashable.js';
import Smasher from './Smasher.js';
import silasHead from '../assets/heads/silas/Silas_Fractured.gltf';

const avg = list => list.reduce((prev, curr) => prev + curr) / list.length;
const max = list => list.sort((a, b) => b.amount - a.amount)[0];

export default class Main {
  constructor({ container: container }) {
    this.container = container;
    this.animatedBg = null;
    this.bgAudio = null;
    this.bgAnalyser = null;
    this.listener = null;
    this.scene = null;
    this.heads = [];
    this.bat = null;
    this.clock = new Clock();
    this.bgColor = [0, 0, 0];
    this.recoveryDuration = 0.1;
    this.recoveryTimer = 0;

    this.composer = null;
    this.glitchPass = null;

    this.renderer = new WebGLRenderer({ alpha: true, canvas: container });
    this.renderer.gammaInput = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio || 1, 1.5));

    this.OnWindowResize = this.OnWindowResize.bind(this);
    window.addEventListener('resize', this.OnWindowResize);

    // this.container.appendChild(this.renderer.domElement);
    this.camera = new PerspectiveCamera(92, window.innerWidth / window.innerHeight, 0.1, 2000);

    this.Animate = this.Animate.bind(this);
  }

  OnWindowResize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.composer.setSize(window.innerWidth, window.innerHeight);
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
  }

  async Initialize(url, modelName, song) {
    let object = await PromisedLoad.GetGLTF(url);

    this.scene = new Scene();
    this.scene.add(this.camera);
    this.camera.position.set(0, 0, 90);

    this.light = new DirectionalLight(new Color(0xffffff), 0.5 );
    this.scene.add(this.light);
    this.light.startX = -40;
    this.light.timer = 0;
    this.light.position.set(this.light.startX, 0, 0);

    // the bat
    let boxGeo = new BoxGeometry(20, 20, 20);
    this.bat = new Smasher(this.container, boxGeo, new MeshPhongMaterial({color: new Color(0x993333), opacity: 0.4}));
    this.bat.canHit = false;
    setTimeout(() => {this.bat.canHit = true}, 5000);
    this.scene.add(this.bat);

    this.camera.lookAt(new Vector3(0, 0, 0))
    // background music
    this.bgAudio = await this.loadAudio(song);
    this.bgAnalyser = new AudioAnalyser( this.bgAudio, 512 );
    // postprocessing
    var renderScene = new RenderPass( this.scene, this.camera );
    this.composer = new EffectComposer( this.renderer );
    this.glitchPass = new GlitchPass();

    this.bloomPass = new UnrealBloomPass( new Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
    this.bloomPass.threshold = 0;
    this.bloomPass.strength = 1.5;
    this.bloomPass.radius = 0;
    this.bloomPass.renderToScreen = true;
    this.composer.addPass( renderScene );
    this.composer.addPass( this.glitchPass );
    this.composer.addPass( this.bloomPass );

    this.renderer.toneMappingExposure = Math.pow( 1, 4.0 );

    // setup head mesh
    this.head = new Smashable(object.scene.getObjectByName(modelName), this.listener, this.glitchPass);
    this.scene.add(this.head);
    this.head.rotateX(Math.PI / 2);
    this.head.bob();
  }

  async loadAudio(url) {
    // create an AudioListener and add it to the camera
    this.listener = new AudioListener();
    this.camera.add( this.listener );

    // create a global audio source
    var sound = new Audio( this.listener );

    // load a sound and set it as the Audio object's buffer
    let audio = await PromisedLoad.GetAudio(url);
    sound.setBuffer( audio );
    sound.setLoop( true );

    sound.setVolume( 0.1 );

    return sound;
  }

  async switchAudio(url) {
    let audio = await PromisedLoad.GetAudio(require(url));
    this.bgAudio.setBuffer(audio);
    this.bgAudio.setLoop(true);
    this.bgAudio.play();
  }

  CheckCollision(delta) {
    if (!this.bat.canHit) return;

    if (this.recovering) {
      this.recoveryTimer = this.recoveryTimer + delta;

      if (this.recoveryTimer > this.recoveryDuration) {
        this.recovering = false;
        this.recoveryTimer = 0;
      }
    }

    var originPoint = this.bat.position.clone();

    for (var vertexIndex = 0; vertexIndex < this.bat.geometry.vertices.length; vertexIndex++) {
      var localVertex = this.bat.geometry.vertices[vertexIndex].clone();
      var globalVertex = localVertex.applyMatrix4(this.bat.matrix);
      var directionVector = globalVertex.sub(this.bat.position);
      var ray = new Raycaster(originPoint, directionVector.clone().normalize());
      var collisionResults = ray.intersectObjects(this.head.children);
      if (collisionResults.length > 0 && collisionResults[0].distance < directionVector.length()) {

        if (!this.recovering) {
          this.head.dynamicMaterial.color.setRGB(
            Math.random(),
            Math.random(),
            Math.random()
          );
        }
        collisionResults.forEach( (collision) => {
          collision.object.recovering = true;
          setTimeout(() => {
            collision.object.recovering = false;
          }, 100);
          this.head.explode(collision.object.name, this.bat.velocity.getAvg());
        });
        this.recovering = true;
      }
    }
  }

  ChangeColors(delta) {
    var dataArray = this.bgAnalyser.getFrequencyData();
    // slice the array into two halves
    var dataAvg = avg(dataArray) / dataArray.length;

    var lowerArray = dataArray.slice(0, (dataArray.length/3) - 1);
    var midArray = dataArray.slice((dataArray/3) -1, 2*(dataArray.length/3) - 1);
    var upperArray = dataArray.slice(2*(dataArray.length/3) - 1, dataArray.length - 1);

    var lowAvg = avg(lowerArray) / lowerArray.length;
    var midsAvg = avg(midArray) / midArray.length;
    var highsAvg = avg(upperArray) / upperArray.length;

    this.animatedBg = this.animatedBg == null ? document.querySelector(".animated-bg") : this.animatedBg;
    if (!this.animatedBg) return;

    this.animatedBg.style = `background-color: rgba(${Math.min(highsAvg * 255, 255)}, ${Math.min(midsAvg * 255, 255)}, ${Math.min(lowAvg * 255, 255)}, 0.3)`
    this.renderer.toneMappingExposure = Math.pow( dataAvg * 13, 4.0 );
  }

  Animate() {
    requestAnimationFrame(this.Animate);
    // this.renderer.render(this.scene, this.camera);
    let delta = this.clock.getDelta();
    this.head.update(this.camera, delta);
    this.bat.update(this.camera, delta);

    this.CheckCollision(delta);
    this.ChangeColors(delta);
    this.composer.render();

    this.light.position.set(
      (Math.sin(this.light.timer) * 40),
      (Math.sin(this.light.timer / 2) * 30),
      (Math.cos(this.light.timer / 3) * 20)
    );
    this.light.timer += delta;
  }
}
