import { Mesh, Object3D, Vector3, Color, MeshPhongMaterial, PositionalAudio, AudioLoader } from 'three-full';
import PromisedLoad from './utils/PromisedLoad';

export default class Smashable extends Object3D {
  constructor(element, listener, glitchPass) {
    // if this object has children meshes, keep track of them
    let childMeshes = [];
    element.traverse((child) => {
      if (child instanceof Mesh) {
        childMeshes.push(child);
      }
    });

    // create a clone from this object
    super(element.clone());
    this.dynamicMaterial =  new MeshPhongMaterial({color: new Color(0x2200ff)});

    // stuff to set up glitching when object's hit
    this.glitchPass = glitchPass;
    this.stopWildingOutTimer = null;

    // current score stuff
    this.currentScoreEl = document.querySelector('.stats-currentscore');
    this.currentHitsEl = document.querySelector('.stats-currenthits');
    this.currentTimeEl = document.querySelector('.stats-currenttime');
    this.score = 0;
    this.hits = 0;
    this.timer = 0;
    this.scored = [(score) => {
      this.score += score;
      this.hits++;
      this.currentScoreEl.innerHTML = `${this.score.toFixed(0)}`;
      this.currentHitsEl.innerHTML = `${this.hits.toFixed(0)}`;
    }];

    // whole buncha highscore stuff
    this.runningScore = document.querySelector(".stats-runningscore");
    this.comboStats = document.querySelector(".stats-highscores");
    this.comboing = false;
    this.comboBreakDuration = 1;
    this.comboBreakTimer = 0;
    this.comboHits = 0;
    this.comboScore = 0;
    this.comboDuration = 0;
    this.bestHits = {hits: 0, score: 0, duration: 0};
    this.bestScore = {hits: 0, score: 0, duration: 0};
    this.bestDuration = {hits: 0, score: 0, duration: 0};
    this.comboBreaker = [(hits, score, duration) => {
      this.dynamicMaterial.wireframe = false;
    }, (hits, score, duration) => {
      this.score = 0;
      this.hits = 0;
      this.timer = 0;
      if (hits > this.bestHits.hits) {
        this.bestHits.hits = hits;
        this.bestHits.score = score;
        this.bestHits.duration = duration;
      }
      if (score > this.bestScore.score) {
        this.bestScore.hits = hits;
        this.bestScore.score = score;
        this.bestScore.duration = duration;
      }
      if (duration > this.bestDuration.duration) {
        this.bestDuration.hits = hits;
        this.bestDuration.score = score;
        this.bestDuration.duration = duration;
      }
      this.comboStats.innerHTML = `
        <span>Best Combo By Hits:</span> ${this.bestHits.score.toFixed(0)} @ ${this.bestHits.hits} hits for ${this.bestHits.duration.toFixed(2)} seconds.<br/>
        <span>Best Combo By Score:</span> ${this.bestScore.score.toFixed(0)} @ ${this.bestScore.hits} hits for ${this.bestScore.duration.toFixed(2)} seconds.<br/>
        <span>Longest Combo:</span> ${this.bestDuration.score.toFixed(0)} @ ${this.bestDuration.hits} hits for ${this.bestDuration.duration.toFixed(2)} seconds.
      `;
    }]; // this is for our callbacks

    // set up audio stuff
    this.listener = listener;
    this.initializeMeshes(childMeshes);

    this.camera = null;

    this.bobbing = false;
    this.initialRotation = 0;
    this.bobX = 0;
    this.bobZ = 0;

    this.explodeVec = new Vector3();
  }

  async initializeMeshes(childMeshes) {
    let audio = await PromisedLoad.GetAudio('/sfx/sfx_movement_portal2.wav');
    // now add the children from the clone
    childMeshes.forEach((mesh) => {
      mesh.material = this.dynamicMaterial;
      this.add(mesh);
      mesh.startingPosition = mesh.position.clone();
      mesh.goalPosition = mesh.position.clone();
      if (mesh instanceof Mesh) {
        mesh.sfx = new PositionalAudio( this.listener );
        mesh.sfx.setBuffer( audio );
        // sound.setLoop( true );
        mesh.sfx.setVolume( 1 );
      }
    });
  }

  bob() {
    this.initialRotation = this.rotation.clone();
    this.bobX = 0;
    this.bobZ = 0;
    this.bobbing = true;
  }

  explode(name, velocity, point) {
    let velLength = velocity.length();
    if (velLength < 0.5) return;
    if (velLength > 1000) return;
    if (isNaN(velLength)) return;

    velocity.negate();
    velocity.set(
      velocity.x,
      velocity.z,
      -velocity.y
    );
    this.dynamicMaterial.wireframe = true;
    this.traverse( (child) => {
      if (child instanceof Mesh) {
        if (child.name == name) {
          if (child.sfx.isPlaying) child.sfx.stop();
          child.sfx.play();
          child.goalPosition.addVectors(child.goalPosition, velocity);
          this.comboing = true;
          this.comboBreakTimer = 0;
          this.comboHits++;
          this.comboScore += velLength;
          let scoreEl = document.createElement('div');

          this.scored.forEach((cb) => cb(velLength));

          scoreEl.innerHTML = `+ ${velLength.toFixed(0)}`;

          this.runningScore.prepend(
            scoreEl
          );
          setTimeout( () => {
            this.runningScore.removeChild(scoreEl);
          }, 1000);
          // if (this.glitchPass.goWild) {
          //   clearTimeout(this.stopWildingOutTimer);
          // }
          // this.stopWildingOutTimer = setTimeout(() => this.glitchPass.goWild = false, this.comboBreakDuration);
          // this.glitchPass.goWild = true;
        }
      }
    });
  }

  handleExplosion(delta) {
    delta = Math.min(delta, 300);

    this.traverse( (child) => {
      if (child instanceof Mesh) {

        this.explodeVec.subVectors(child.goalPosition, child.position);
        child.position.add(this.explodeVec.multiplyScalar(delta));

        // return to start position gradually
        this.explodeVec.subVectors(child.startingPosition, child.position);
        child.goalPosition.add(this.explodeVec.multiplyScalar(delta));
      }
    });
  }

  addBg(audio) {
    this.traverse( (child) => {
      if (child instanceof Mesh) {
        child.add(audio);
        return;
      }
    });
  }

  checkCombo(delta) {
    if (this.comboing) {
      if (this.comboBreakTimer > this.comboBreakDuration) {
        this.comboing = false;
        this.comboBreakTimer = 0;
        this.comboBreaker.forEach( (cb) => {
          cb(this.comboHits, this.comboScore, this.comboDuration);
        })
        this.timer = 0;
        this.comboHits = 0;
        this.comboScore = 0;
        this.comboDuration = 0;
      } else {
        this.timer += delta;
        this.currentTimeEl.innerHTML = `${this.timer.toFixed(2)}`;
        this.comboBreakTimer += delta;
        this.comboDuration += delta;
      }
    }
  }

  update(camera, delta) {
    if (!this.camera) this.camera = camera;
    if (this.bobbing) {
      // this.rotation.x = this.initialRotation.x + ( Math.sin(this.bobX / 100) / 2);
      // this.rotation.z = this.initialRotation.z + ( Math.sin(this.bobZ / 200) / 2);
      this.bobX++;
      this.bobZ++;
    }

    this.handleExplosion(delta);
    this.checkCombo(delta);
  }
}
